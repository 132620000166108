import React, { useEffect } from 'react';
import { elephant } from "./img";

export function Loading() {
    useEffect(() => {
        const loadingP = document.getElementById('loadingP');
        
        if (loadingP) {
            let isFaded = false;
            const intervalId = setInterval(() => {
                loadingP.style.opacity = isFaded ? '80%' : '20%';
                isFaded = !isFaded;
            }, 500);

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <div className="loading">
            <p id="loadingP">loading...</p>
            <img id="elephantPic" src={elephant} alt="elephant" />
        </div>
    );
}
