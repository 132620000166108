import React, { useState, useEffect, useRef } from 'react';

const tel = window.Telegram.WebApp;
const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id || '627083512';

// Function to fetch frens points
const fetchFrensPoints = async (userId: string): Promise<number> => {
    try {
        const response = await fetch('https://elserver.dancemonkey.fun/frens-points', {
            method: 'GET',
            headers: { 'User-ID': userId },
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error message
            throw new Error(errorText);
        }

        const result = await response.json();
        return result.frensPoints;
    } catch (error) {
        console.error('Error fetching frens points:', error);
        throw error;
    }
};

// Function to fetch dice points
const fetchDicePoints = async (userId: string): Promise<number> => {
    try {
        const response = await fetch('https://elserver.dancemonkey.fun/dice-points', {
            method: 'GET',
            headers: { 'User-ID': userId },
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error message
            throw new Error(errorText);
        }

        const result = await response.json();
        return result.dicePoints; // Ensure the correct key for dice points
    } catch (error) {
        console.error('Error fetching dice points:', error);
        throw error;
    }
};

// Function to fetch all points
const fetchAllPoints = async (userId: string): Promise<number> => {
    try {
        const response = await fetch('https://elserver.dancemonkey.fun/all-points', {
            method: 'GET',
            headers: { 'User-ID': userId },
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error message
            throw new Error(errorText);
        }

        const result = await response.json();
        
        return result.allPoints;
    } catch (error) {
        console.error('Error fetching all points:', error);
        throw error;
    }
};

// Function to fetch Btc points
const fetchBtcPoints = async (userId: string): Promise<number> => {
    try {
        const response = await fetch('https://elserver.dancemonkey.fun/all-points', {
            method: 'GET',
            headers: { 'User-ID': userId },
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error message
            throw new Error(errorText);
        }

        const result = await response.json();
        
        return result.btcPoints;
    } catch (error) {
        console.error('Error fetching all points:', error);
        throw error;
    }
};

export function Glasses() {
    const [frensPoints, setFrensPoints] = useState<number>(() => {
        try {
            const savedPoints = localStorage.getItem('frensPoints');
            return savedPoints ? JSON.parse(savedPoints) : 0;
        } catch {
            return 0;
        }
    });

    const [diceRollPoints, setDicePoints] = useState<number>(() => {
        try {
            const savedPoints = localStorage.getItem('diceRollPoints');
            return savedPoints ? JSON.parse(savedPoints) : 0;
        } catch {
            return 0;
        }
    });

    const [allPoints, setAllPoints] = useState<number>(() => {
        try {
            const savedPoints = localStorage.getItem('allPoints');
            return savedPoints ? JSON.parse(savedPoints) : 0;
        } catch {
            return 0;
        }
    });

    const [btcPoints, setBtcPoints] = useState<number>(() => {
        try {
            const savedPoints = localStorage.getItem('btcPoints');
            return savedPoints ? JSON.parse(savedPoints) : 0;
        } catch {
            return 0;
        }
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const tasksPoints = 0; // Replace this with actual logic

    const handleTouchStart = useRef<number | null>(null);
    const handleTouchMove = useRef<number | null>(null);

    useEffect(() => {
        const handleTouchStartEvent = (e: TouchEvent) => {
            handleTouchStart.current = e.touches[0].clientY;
        };

        const handleTouchMoveEvent = (e: TouchEvent) => {
            handleTouchMove.current = e.touches[0].clientY;
        };

        const handleTouchEndEvent = async (e: TouchEvent) => {
            if (handleTouchStart.current == null || handleTouchMove.current == null) {
                return;
            }

            const distance = handleTouchMove.current - handleTouchStart.current;
            if (distance > 100) { // Threshold for triggering refresh
                handleTouchStart.current = null;
                handleTouchMove.current = null;

                // Trigger data fetch
                await fetchData();
            }
        };

        // Use `as unknown` to satisfy TypeScript
        document.addEventListener('touchstart', handleTouchStartEvent as unknown as EventListener);
        document.addEventListener('touchmove', handleTouchMoveEvent as unknown as EventListener);
        document.addEventListener('touchend', handleTouchEndEvent as unknown as EventListener);

        return () => {
            document.removeEventListener('touchstart', handleTouchStartEvent as unknown as EventListener);
            document.removeEventListener('touchmove', handleTouchMoveEvent as unknown as EventListener);
            document.removeEventListener('touchend', handleTouchEndEvent as unknown as EventListener);
        };
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const fetchedFrensPoints = await fetchFrensPoints(userId);
            setFrensPoints(fetchedFrensPoints);
            localStorage.setItem('frensPoints', JSON.stringify(fetchedFrensPoints));

            const fetchedBtcPoints = await fetchBtcPoints(userId);
            setBtcPoints(fetchedBtcPoints);
            localStorage.setItem('btcPoints', JSON.stringify(fetchedBtcPoints));

            const fetchedDicePoints = await fetchDicePoints(userId);
            setDicePoints(fetchedDicePoints);
            localStorage.setItem('diceRollPoints', JSON.stringify(fetchedDicePoints));

            const fetchedAllPoints = await fetchAllPoints(userId);
            setAllPoints(fetchedAllPoints);
            localStorage.setItem('allPoints', JSON.stringify(fetchedAllPoints));
        } catch (err) {
            console.log('error fetching points');
        } finally {
            setLoading(false);
        }
    };

    const displayFrensPoints = frensPoints !== null ? frensPoints : 'Loading...';
    const displayDicePoints = diceRollPoints !== null ? diceRollPoints : 'Loading...';
    const displayAllPoints = allPoints !== null ? allPoints : 'Loading...';

    return (
        <div className="glasses">
            <div id='glass1' className="glass">
                <div className="upGlass">
                    All Points
                </div>
                <div className="downGlass" style={{ fontSize: loading ? 'large' : 'xx-large' }}>
                    {loading ? <div className="spinner"></div> : error ? error : displayAllPoints}
                </div>
            </div>
            <div id='glass2' className="glass">
                <div className="upGlass">
                    Dice Roll Points
                </div>
                <div className="downGlass" style={{ fontSize: loading ? 'large' : 'xx-large' }}>
                    {loading ? <div className="spinner"></div> : error ? error : displayDicePoints}
                </div>
            </div>
            <div id='glass3' className="glass">
                <div className="upGlass">
                    BTC Price Points
                </div>
                <div className="downGlass" style={{ fontSize: loading ? 'large' : 'xx-large' }}>
                    {loading ? <div className="spinner"></div> : error ? error : btcPoints}
                </div>
            </div>
            <div id='glass4' className="glass">
                <div className="upGlass">
                    Tasks Points
                </div>
                <div className="downGlass" style={{ fontSize: loading ? 'large' : 'xx-large' }}>
                    {loading ? <div className="spinner"></div> : error ? error : tasksPoints}
                </div>
            </div>
            <div id='glass5' className="glass">
                <div className="upGlass">
                    Referral Points
                </div>
                <div className="downGlass" style={{ fontSize: loading ? 'large' : 'xx-large' }}>
                    {loading ? <div className="spinner"></div> : error ? error : displayFrensPoints}
                </div>
            </div>
        </div>
    );
}
