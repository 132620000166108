import React, { useState, Dispatch, SetStateAction } from 'react';
import { home, coins, frens, elefante } from './img/index';
import { Glasses } from './glasses';
import { HomePage } from './HomePage';
import { Frens } from './Frens';
import { Tasks } from './Tasks';

// Define a type for the props
interface MenuProps {
  setStateMenu: Dispatch<SetStateAction<number>>;
}

// Update Menu component to receive props with types
export function Menu({ setStateMenu }: MenuProps) {
  return (
    <div className="menu">
      <div className="menuDiv" onClick={() => setStateMenu(1)}>
        <img id='menuImg' src={home} alt="Home" />
      </div>
      <div className="menuDiv" onClick={() => setStateMenu(2)}>
        <img id='menuImg' src={coins} alt="Coins" />
      </div>
      <div className="menuDiv" onClick={() => setStateMenu(3)}>
        <img id='menuImg' src={frens} alt="Frens" />
      </div>
      <div className="menuDiv" onClick={() => setStateMenu(4)}>
        <img id='menuImg' src={elefante} alt="Elefante" />
      </div>
    </div>
  );
}

// Container component
export function Container() {
  const [stateMenu, setStateMenu] = useState(1);

  const renderContent = () => {
    if (stateMenu === 1) {
      return <HomePage />;
    } else if (stateMenu === 2) {
      return <Glasses />;
    } else if (stateMenu === 3) {
      return <Frens />;
    } else if (stateMenu === 4) {
      return <Tasks />;
    }
  };

  return (
    <div>
      <Menu setStateMenu={setStateMenu} />
      {renderContent()}
    </div>
  );
}
