import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { dice, up, down } from './img';
import TradingViewSymbolInfo from './Trading';

export function HomePage() {
    const [dailyPoints, setDailyPoints] = useState<number>(() => {
        const savedPoints = localStorage.getItem('dailyPoints');
        return savedPoints ? parseInt(savedPoints, 10) : 0;
    });
    const [allPoints, setAllPoints] = useState<number>(() => 0);
    const [todaysRolledNumber, setTodaysRolledNumber] = useState<number | null>(null);
    const [isDiceDisabled, setIsDiceDisabled] = useState<boolean>(false);
    const [isBetDisabled, setIsBetDisabled] = useState<boolean>(false);
    const [isBetStarted, setIsBetStarted] = useState<boolean>(false);
    const [isBullish, setIsBullish] = useState<boolean>(false);
    const [isBearish, setIsBearish] = useState<boolean>(false);
    const [completedBet, setCompletedBet] = useState<boolean>(false);
    const [userWon, setUserWon] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [showRolledNumber, setShowRolledNumber] = useState<boolean>(false);
    const [morePoints, setMorePoints] = useState<number | null>(null);
    const [betTime, setBetTime] = useState<Date | null>(null);
    const [timer, setTimer] = useState<string>('..h:..m:..s');
    const [btcPrice, setBtcPrice] = useState<number | null>(null);
    const [userBtcPrice, setUserBtcPrice] = useState<number | null>(null);

    const calculateRemainingTime = (betTime: Date | null): string => {
        if (!betTime) return 'No bet placed';
        
        const endTime = new Date(betTime.getTime() + 4 * 60 * 60 * 1000); // 24 hours later
        const now = new Date();
        const remainingTime = endTime.getTime() - now.getTime();
        
        if (remainingTime <= 0) {
            completedGame();
        };
        
        const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    const handleDiceRoll = async () => {
        if (isDiceDisabled) {
            window.Telegram.WebApp.showAlert('Rolled Today :(');
            return;
        };

        setLoading(true);
        try {
            const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '627083512';
            const response = await fetch('https://elserver.dancemonkey.fun/dice', {
                method: 'POST',
                headers: { 'User-ID': userId, 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                const result = await response.json();
                setIsDiceDisabled(true);
                setError(result.message);
                return;
            }

            const result = await response.json();
            const newDailyPoints = dailyPoints + result.rolledNumber;
            setTodaysRolledNumber(result.rolledNumber);
            setDailyPoints(newDailyPoints);
            localStorage.setItem('dailyPoints', newDailyPoints.toString());
            setIsDiceDisabled(true);

            setShowRolledNumber(true);
            setTimeout(() => {
                setShowRolledNumber(false);
            }, 2000);
            fetchAllPoints();
        } catch (error) {
            console.error('Error rolling the dice:', error);
            setIsDiceDisabled(true);
        } finally {
            setLoading(false);
        }
    };

    const buyTickets = async (bullOrBear: number, btcPrice: number) => {
        if (isBetDisabled) return; // Check if betting is disabled
    
        try {
            const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '627083512';
            const response = await fetch('https://elserver.dancemonkey.fun/buy-ticket', {
                method: 'POST',
                headers: {
                    'User-ID': userId,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ bullOrBear, btcPrice }) // Include the bullOrBear and btcPrice values in the request body
            });
    
            if (!response.ok) {
                const result = await response.json();
                setError(result.message); // Set error message from response
                return;
            }
    
            const result = await response.json();
            window.location.reload(); // Optionally log or process the response data
    
        } catch (error) {
            console.error('Error making bet:', error);
        } finally {
            setIsDiceDisabled(false); // Ensure button is re-enabled
        }
    };
    
    const fetchAllPoints = async () => {
        setLoading(true);
        try {
            const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '627083512';
            const response = await axios.get('https://elserver.dancemonkey.fun/all-points', {
                headers: { 'User-ID': userId },
            });

            const result = response.data;
        
            setAllPoints(result.allPoints);
            setMorePoints(900 - result.allPoints);

            if (result.allPoints < 899) {
                setIsBetDisabled(true);
            } else if (result.allPoints >= 900) {
                setIsBetDisabled(false);
            }
        } catch (error) {
            console.error('Error fetching all points:', error);
            setError('0');
        } finally {
            setLoading(false);
        }
    };

    const fetchBetStatus = async () => {
        try {
            const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '627083512';
    
            const response = await axios.get('https://elserver.dancemonkey.fun/bet-state', {
                headers: { 'User-ID': userId },
            });
    
            const result = response.data;
            setUserBtcPrice(result.btcPrice);
            setBetTime(result.betTime ? new Date(result.betTime) : null);
            setIsBetStarted(result.isBetStarted);
            setCompletedBet(result.completedBet);
            if (result.isBetStarted) {
                setIsBearish(result.bullOrBear === 0);
                setIsBullish(result.bullOrBear === 1);
            }
        } catch (error) {
            console.error('Error fetching bet status:', error);
            setError('0');
        }
    };
    
    const fetchBtcPrice = async () => {
        try {
            const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
                params: {
                    ids: 'bitcoin',
                    vs_currencies: 'usd'
                }
            });
            const btcPrice = response.data.bitcoin.usd;
            setBtcPrice(btcPrice);
            console.log(btcPrice);
        } catch (error) {
            console.error('Error fetching BTC price:', error);
            setError('Try Again!');
            setBtcPrice(0); // Ensure btcPrice is explicitly set to 0 on error
        }
    };
    
    const completedGame = () => {
        if (userBtcPrice && btcPrice) {
            if (isBullish) {
                if (userBtcPrice - btcPrice < 0) {
                    window.Telegram.WebApp.showAlert('You Won 2000 Points!');
                    gameEnd(1); // user wins
                } else if (userBtcPrice - btcPrice > 0) {
                    window.Telegram.WebApp.showAlert(':) You Loose!');
                    gameEnd(0); // user loses
                }
            } else if (isBearish) {
                if (userBtcPrice - btcPrice > 0) {
                    window.Telegram.WebApp.showAlert('You Won 2000 Points!');
                    gameEnd(1); // user wins
                } else if (userBtcPrice - btcPrice < 0) {
                    window.Telegram.WebApp.showAlert(':) You Loose!');
                    gameEnd(0); // user loses
                }
            }
        }
    };

    useEffect(() => {
        const updateTimer = () => {
            if (betTime) {
                const time = calculateRemainingTime(betTime);
                setTimer(time);
            }
        };

        const intervalId = setInterval(updateTimer, 1000);
        return () => clearInterval(intervalId);
    }, [betTime]);

    useEffect(() => {
        const checkDiceStatus = async () => {
            setLoading(true);
            try {
                const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '627083512';
                const response = await fetch('https://elserver.dancemonkey.fun/dice/check', {
                    method: 'GET',
                    headers: { 'User-ID': userId },
                });

                if (!response.ok) {
                    const result = await response.json();
                    setIsDiceDisabled(true);
                    setDailyPoints(result.dailyPoints);
                    localStorage.setItem('dailyPoints', result.dailyPoints);
                } else {
                    const result = await response.json();
                    setIsDiceDisabled(!result.canRoll);
                    setDailyPoints(result.dailyPoints);
                    localStorage.setItem('dailyPoints', result.dailyPoints);
                }
            } catch (error) {
                console.error('Error checking dice roll status:', error);
                setIsDiceDisabled(true);
            } finally {
                setLoading(false);
            }
        };
        fetchBtcPrice();
        checkDiceStatus();
        fetchAllPoints();
        fetchBetStatus(); // Fetch BTC price on component mount
    }, []);

    const startBet = async (bullOrBear: number) => {
        if (btcPrice === null) {
            console.error('BTC price is not available.');
            return;
        }
        
        if (bullOrBear === 1) {
            buyTickets(1, btcPrice);
        } else if (bullOrBear === 0) {
            buyTickets(0, btcPrice);
        }
    };

    const gameEnd = async (userWin: number) => {
    
        try {
            const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '627083512';
            const response = await fetch('https://elserver.dancemonkey.fun/game-end', {
                method: 'POST',
                headers: {
                    'User-ID': userId,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userWin, btcPrice })
            });
    
            if (!response.ok) {
                const result = await response.json();
                setError(result.message); // Set error message from response
                return;
            }
            
            const result = await response.json();
            console.log(result);
            window.location.reload();
    
        } catch (error) {
            console.error('Error making bet:', error);
        }
    };

    return (
        <div className="homePage">
            <div className="homeLogo">
                <div className="rightHomeLogo" style={{
                    color: 'white', fontSize: isDiceDisabled ? 'x-large' : 'x-large',
                    backgroundColor: loading && isBetDisabled ? '' : 'rgba(100, 100, 100, 0.3)'
                }}>
                    {loading ? <div className="spinner"></div> : allPoints}
                </div>
                <div className="leftHomeLogo" style={{
                    backgroundColor: isDiceDisabled ? 'rgba(100, 0, 0, 0.2)' : 'rgba(0, 100, 0, 0.2)'
                }}>
                    <img
                        id="diceImage"
                        src={dice}
                        onClick={handleDiceRoll}
                        style={{
                            pointerEvents: isDiceDisabled ? 'none' : 'auto',
                            opacity: isDiceDisabled ? 0.5 : 1,
                        }}
                        alt="Dice"
                    />
                    {showRolledNumber && todaysRolledNumber !== null && (
                        <div className="todaysRolledNumber fade-out">
                            <p>+{todaysRolledNumber}</p>
                            
                        </div>
                    )}
                </div>
            </div>
            <div className="homeAds1">
                <TradingViewSymbolInfo />
            </div>
            <div className="homeAds2" onClick={() => {
                if (isBetDisabled) {
                    window.Telegram.WebApp.showAlert('You Need ' + morePoints + ' More Points To Bet!' );
                } else if (isBetStarted) {
                    window.Telegram.WebApp.showAlert('You Have a Bet :(');
                } else if (!isBetDisabled || !isBetStarted) {
                    window.Telegram.WebApp.showConfirm('Are You Really Bullish?', (confirmed)=>{
                        if (confirmed) {
                            startBet(1);
                          }
                    });
                }
            }} style={{
                border: isBullish ? 'dashed white 1px' : ''
            }}>
                <div className="leftBullish">
                    <p id='bullishP'>{ isBullish && isBetStarted ? userBtcPrice + ' > ' + timer : 'Bullish For 4 Hours Later.'}</p>
                </div>
                <div className="rightBullish">
                    <img id='upMark' src={up}/>
                </div>
            </div>
            <div className="homeAds3" onClick={() => {
                if (isBetDisabled) {
                    window.Telegram.WebApp.showAlert('You Need ' + morePoints + ' More Points To Bet!' );
                } else if (isBetStarted) {
                    window.Telegram.WebApp.showAlert('You Have a Bet :(');
                } else if (!isBetDisabled || !isBetStarted) {
                    window.Telegram.WebApp.showConfirm('Are You Really Bearish?', (confirmed)=>{
                        if (confirmed) {
                            startBet(0);
                        }
                    });
                    
                }
            }} style={{
                border: isBearish ? 'dashed white 1px' : ''
            }}>
                <div className="leftBearish">
                    <p id='bearishP'>{ isBearish && isBetStarted ? userBtcPrice + ' < ' + timer : 'Bearish For 4 Hours Later.'}</p>
                </div>
                <div className="rightBearish">
                    <img id='downMark' src={down}/>
                </div>
            </div>
        </div>
    );
}
