import React, { useState, useEffect } from 'react';
import './App.css';
import { Container } from './menu';
import { Loading } from './loading';

const App: React.FC = () => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    // Ensure window.Telegram.WebApp is available before accessing its properties
    if (window.Telegram?.WebApp) {
      const tel = window.Telegram.WebApp;
      tel.headerColor = '#000000';
      tel.expand();
      tel.enableClosingConfirmation();
      tel.isVerticalSwipesEnabled = false;
    }

    // Timer to show loading screen
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <div className="main">
        {showLoading ? <Loading /> : <Container />}
      </div>
    </div>
  );
}

export default App;
