import { airdrop } from "./img";
export function Tasks() {
    return(
        <div className="tasks">
            <div className="tasksLogo">
                <p>Elefante Roadmap 🐘</p>
            </div>
            <div className="elefanteTasks">
            Elefante Tasks
            </div>
            <div className="convertPoints">
            Convert Points To NFTs
            </div>
            <div className="goldenNFT">
            Golden NFT
            </div>
            <div className="elefanteAirdrop">
            <p>Elefante Airdrop</p> <img id="airdropIcon" src={airdrop}/>
            </div>
        </div>
    );
}