import React, { useState, useEffect, useRef } from 'react';
import { frensImage } from './img';

const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id || '627083512';

// Function to fetch frens count
const fetchFrensCount = async (userId: string): Promise<number> => {
    try {
        const response = await fetch('https://elserver.dancemonkey.fun/frens-count', {
            method: 'GET',
            headers: { 'User-ID': userId },
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error message
            throw new Error(errorText);
        }

        const result = await response.json();
        return result.frensCount;
    } catch (error) {
        console.error('Error fetching frens count:', error);
        throw error;
    }
};

export function Frens() {
    const [frensCount, setFrensCount] = useState<number | null>(() => {
        const savedCount = localStorage.getItem('frensCount');
        return savedCount ? JSON.parse(savedCount) : null;
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleTouchStart = useRef<number | null>(null);
    const handleTouchMove = useRef<number | null>(null);

    useEffect(() => {
        const getFrensCount = async () => {
            setLoading(true);
            try {
                const count = await fetchFrensCount(userId);
                setFrensCount(count);
                localStorage.setItem('frensCount', JSON.stringify(count));
            } catch (err) {
                console.log('0');
            } finally {
                setLoading(false);
            }
        };

        if (frensCount === null) {
            getFrensCount();
        }

        const handleTouchStartEvent = (e: TouchEvent) => {
            handleTouchStart.current = e.touches[0].clientY;
        };

        const handleTouchMoveEvent = (e: TouchEvent) => {
            handleTouchMove.current = e.touches[0].clientY;
        };

        const handleTouchEndEvent = async (e: TouchEvent) => {
            if (handleTouchStart.current == null || handleTouchMove.current == null) {
                return;
            }

            const distance = handleTouchMove.current - handleTouchStart.current;
            if (distance > 100) { // Threshold for triggering refresh
                handleTouchStart.current = null;
                handleTouchMove.current = null;

                // Trigger data fetch
                await getFrensCount();
            }
        };

        // Use `as unknown` to satisfy TypeScript
        document.addEventListener('touchstart', handleTouchStartEvent as unknown as EventListener);
        document.addEventListener('touchmove', handleTouchMoveEvent as unknown as EventListener);
        document.addEventListener('touchend', handleTouchEndEvent as unknown as EventListener);

        return () => {
            document.removeEventListener('touchstart', handleTouchStartEvent as unknown as EventListener);
            document.removeEventListener('touchmove', handleTouchMoveEvent as unknown as EventListener);
            document.removeEventListener('touchend', handleTouchEndEvent as unknown as EventListener);
        };
    }, [frensCount]);

    return (
        <div className="frens">
            <div className="frensLogo">
                <div className="frensP">
                    Your<br />Frens
                </div>
                <div className="frensNum" style={{
                    fontSize: loading ? 'large' : 'xxx-large',
                    backgroundColor: loading ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 215, 0, 0.5)'
                }}>
                    {loading ? <div className="spinner"></div> : error ? error : frensCount}
                </div>
            </div>
            <div className="frensImage">
                <img id="frensImg" src={frensImage} alt="Frens"/>
            </div>
            <div className="frensAds">
                <div className="frensInviteP">
                    Invite Your Frens
                    <br />
                    To Get More Elefantes
                </div>
                <div className="frensLink">
                    <div className="link">
                        <p>http://t.me/elefantehouse_bot?start=fren={userId}</p>
                    </div>
                    <div className="btn">
                        <button
                            id="copyLink"
                            onClick={() => {
                                navigator.clipboard.writeText('http://t.me/elefantehouse_bot?start=fren=' + userId);
                                window.Telegram.WebApp.showAlert('Copied!');
                            }}
                        >
                            Copy
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
