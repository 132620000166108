import React, { useEffect } from 'react';

const TradingViewSymbolInfo: React.FC = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/gecko-coin-price-chart-widget.js';
    script.async = true;

    // Append script to the document body
    document.body.appendChild(script);

    // Clean up by removing the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div user-select="none">
      <gecko-coin-price-chart-widget
        locale="en"
        dark-mode="true"
        transparent-background="true"
        coin-id="bitcoin"
        initial-currency="usd"
      ></gecko-coin-price-chart-widget>
    </div>
  );
};

export default TradingViewSymbolInfo;
